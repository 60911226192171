<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <validation-provider v-slot="{ errors }" name="identification"
                             rules="required|nit">
          <v-text-field
            vid="identification"
            v-model="model.identification"
            type="text"
            outlined
            dense
            :error-messages="errors"
            :label="rutLabel +  ' (Sin código de verificación)'"
            required
            @blur="validateActiveBookings"
            @keypress="isLetterOrNumber($event)"
          ></v-text-field>
        </validation-provider>
      </div>
      <div class="col-12 col-md-6">
        <validation-provider
          v-slot="{ errors }"
          name="Razón Social"
          rules="required|max:80"
        >
          <v-text-field
            vid="businessName"
            v-model="model.businessName"
            type="text"
            outlined
            dense
            :error-messages="errors"
            label="Razón social"
            required
          ></v-text-field>
        </validation-provider>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6" v-if="countries">
        <validation-provider v-slot="{ errors }" name="País" rules="required">
          <v-select
            vid="countryId"
            v-model="model.countryId"
            :error-messages="errors"
            :items="countries"
            @change="getDepartments"
            outlined
            dense
            item-text="name"
            item-value="id"
            label="País"
          />
        </validation-provider>
      </div>
      <div class="col-12 col-md-6">
        <validation-provider v-slot="{ errors }" name="Departamento" rules="required">
          <v-select
            vid="departmentId"
            v-model="model.departmentId"
            :error-messages="errors"
            :items="departments"
            @change="getCities"
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Departamento/Estado"
          />
        </validation-provider>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <validation-provider v-slot="{ errors }" name="Ciudad" rules="required">
          <v-select
            vid="cityId"
            v-model="model.cityId"
            :error-messages="errors"
            :items="cities"
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Ciudad"
          ></v-select>
        </validation-provider>
      </div>
      <div class="col-12 col-md-6">
        <validation-provider
          v-slot="{ errors }"
          name="Nombre comercial"
          rules="required|max:80"
        >
          <v-text-field
            vid="name"
            v-model="model.name"
            type="text"
            outlined
            dense
            :error-messages="errors"
            label="Nombre comercial"
            required
          ></v-text-field>
        </validation-provider>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <validation-provider
          v-slot="{ errors }"
          name="Correo corporativo"
          rules="email"
        >
          <v-text-field
            vid="email"
            v-model="model.email"
            type="email"
            outlined
            dense
            :error-messages="errors"
            label="Correo corporativo"
            required
          ></v-text-field>
        </validation-provider>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex flex-wrap">
          <div class="flex-grow-1">
            <validation-provider
              v-slot="{ errors }"
              name="Teléfono de la empresa"
              :rules="{required: true,phone: true}"
            >
              <vue-tel-input-vuetify
                label="Teléfono de la empresa"
                :error-messages="errors"
                class="flex-wrap"
                mode="international"
                :inputOptions="{showDialCode:false}"
                required
                outlined
                dense
                :preferred-countries="['CO', 'US']"
                v-model="model.phone"
              />
            </validation-provider>
          </div>
          <div class="flex-grow-1">
            <validation-provider
              v-slot="{ errors }"
              name="Extensión"
              rules="integer"
            >
              <v-text-field
                vid="phoneExtension"
                v-model="model.phoneExtension"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Extensión"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <validation-provider
          v-slot="{ errors }"
          name="Dirección"
          rules="required|max:80"
        >
          <v-text-field
            vid="address"
            v-model="model.address"
            type="text"
            outlined
            dense
            :error-messages="errors"
            label="Dirección"
            required
          ></v-text-field>
        </validation-provider>
      </div>

      <div class="col-12 col-md-6">
        <validation-provider
          v-slot="{ errors }"
          name="Página Web"
          rules="max:80"
        >
          <v-text-field
            vid="webSite"
            v-model="model.webSite"
            type="text"
            outlined
            dense
            :error-messages="errors"
            label="Página Web"
            required
          ></v-text-field>
        </validation-provider>
      </div>

    </div>
  </div>
</template>

<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import { CITIES_URL, COUNTRIES_URL, DEPARTMENTS_URL, VALIDATE_ACTIVE_BOOKINGS } from '@/constants/ServicesConstants'
import identificationMixin from '@/mixins/identificationMixin'

export default {
  async created () {
    await this.getCountries()
  },
  data () {
    return {
      rutLabel: process.env.VUE_APP_RUT_LABEL,
      cities: [],
      countries: [],
      departments: [],
      peruFair: process.env.VUE_APP_PERU_FAIR === '1'
    }
  },
  methods: {
    establishPaymentCurrency () {
      const country = this.countries.find(item => item.id === this.model.countryId)
      this.model.copPaymentCurrency = country.name === 'Colombia' ? 'COP' : country.name === 'Perú' && this.peruFair ? 'PEN' : 'USD'
    },
    async getCities () {
      try {
        this.showLoader()
        const response = await this.getById(CITIES_URL, this.model.departmentId)
        this.cities = response.data
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async getCountries () {
      try {
        this.showLoader()
        this.departments = []
        this.cities = []
        const response = await this.get(COUNTRIES_URL)
        this.countries = response.data
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async getDepartments () {
      this.establishPaymentCurrency()
      try {
        this.showLoader()
        this.cities = []
        const response = await this.getById(DEPARTMENTS_URL, this.model.countryId)
        this.departments = response.data
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async validateActiveBookings () {
      this.showLoader()
      const response = await this.post(VALIDATE_ACTIVE_BOOKINGS, {
        identification: this.model.identification,
        fairId: this.fairId
      })
      if (response && response.status === 200) {
        if (!response.data) {
          this.$emit('activeBooking')
        }
      }
      this.hideLoader()
    }
  },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin, identificationMixin],
  name: 'CompanyInfo',
  props: {
    model: {
      default: null,
      type: Object
    },
    fairId: {
      default: null,
      type: Number
    }
  }
}
</script>
